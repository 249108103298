import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import GatsbyImage from 'gatsby-image';
import { graphql } from 'gatsby';
import { rem, rgba, position } from 'polished';

import Button from './Button';
import { P, Headline } from './Typography';

import { fluidImageType } from '../helpers/prop-types';
import { colors } from '../helpers/variables';
import { gapable } from '../helpers/traits';

/** Multipel verwendeter Box-Shadow */
const boxShadow = `box-shadow: 0px 0px 4px ${rgba(colors.black, 0.15)};`;

/** Breakpoint für die Komponente Mobile <-> Desktop */
const elementBreakpoint = 660;

/** Wrapper um die Komponente */
const Wrapper = styled.div`
    @media (min-width: ${elementBreakpoint}px) {
        display: flex;

        &.textimgbox--reverse {
            flex-direction: row-reverse;
        }
    }
    align-items: center;
    max-width: ${rem(1230)};
    ${gapable('none')};
`;

/** Text der Box */
const Text = styled.div`
    ${boxShadow};
    flex-shrink: 0;
    background-color: ${colors.white};
    padding: 1.25em 1.5em 1.5em;

    @media (min-width: ${elementBreakpoint}px) {
        padding: 2em;
        transform: translateX(-${rem(20)});
        width: ${(650 * 100) / 1230}%;
        .textimgbox--reverse & {
            transform: translateX(${rem(20)});
        }
    }
`;

/** Das Bild der Box */
const Img = styled(GatsbyImage)`
    ${boxShadow};
    flex-shrink: 0;

    @media (min-width: ${elementBreakpoint}px) {
        width: ${(600 * 100) / 1230}%;
    }

    &:after {
        ${position('absolute', 0, null, null, 0)};
        border-top: 3px solid ${colors.primary};
        border-left: 3px solid ${colors.primary};
        content: '';
        display: block;
        width: 13.35%;
        height: 20%;

        .textimgbox--reverse & {
            ${position('absolute', 0, 0, null, 'auto')};
            border-top: 3px solid ${colors.primary};
            border-left: none;
            border-right: 3px solid ${colors.primary};
        }
    }
`;

/**
 * TextImgBox
 * @param {string} props.headline Headline der Box
 * @param {string} props.content Inhalt der Box
 * @param {fluidImageType} props.image Bild der Box
 * @param {object} props.button Daten für den Button
 * @param {string} props.gap Optional: Abstand nach unten
 * @param {string} props.reverse Optional: Ausrichtung (links / rechts)
 * @example <TextImgBox>...</TextImgBox>
 */
const TextImgBox = ({ headline, content, image, button, gap, reverse }) => {
    return (
        <Wrapper gap={gap} className={reverse ? 'textimgbox--reverse' : ''}>
            <Img fluid={image.fluid} />
            <Text>
                <Headline level="h3">{headline}</Headline>
                <P>{content}</P>
                <Button to={button.to}>{button.text}</Button>
            </Text>
        </Wrapper>
    );
};

TextImgBox.propTypes = {
    headline: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    image: fluidImageType.isRequired,
    button: PropTypes.shape({
        text: PropTypes.string.isRequired,
        to: PropTypes.string.isRequired,
    }).isRequired,
    gap: PropTypes.string,
    reverse: PropTypes.bool,
};

TextImgBox.defaultProps = {
    gap: null,
    reverse: false,
};

export default TextImgBox;

/**
 * GraphQL Fragment für GatsbyImage
 * @type {GraphQL}
 */
// eslint-disable-next-line no-undef
export const query = graphql`
    fragment TextImgBoxFluid on File {
        childImageSharp {
            fluid(maxWidth: 600, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
`;
